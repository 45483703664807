import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import CategoryArchive from '../components/CategoryArchive';

const categoryArchive = ({ data }) => {
  const {
    sanityCategory: { seoSettings, title },
  } = data;
  return (
    <Wrapper className="category-archive" seo={seoSettings} title={title}>
      <CategoryArchive
        {...data.sanityCategory}
        paintings={data.firstNinePaintings.edges.map(edge => ({
          ...edge.node,
        }))}
        rest={data.restOfPaintings.edges.map(edge => ({ ...edge.node }))}
      />
    </Wrapper>
  );
};

export const CategoryArchiveQuery = graphql`
  query CategoryArchiveQuery($id: String!) {
    sanityCategory(id: { eq: $id }) {
      title
      _rawDescription(resolveReferences: { maxDepth: 10 })
      seoSettings {
        metaDescription
        title
      }
    }
    firstNinePaintings: allSanityPainting(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      limit: 9
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...GalleryPainting
        }
      }
    }
    restOfPaintings: allSanityPainting(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      skip: 9
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...GalleryPainting
        }
      }
    }
  }
`;

export const paintingQuery = graphql`
  fragment GalleryPainting on SanityPainting {
    id
    title
    slug {
      current
    }
    _key
    info
    images {
      alt
      asset {
        assetId
        id
        sha1hash
        _id
        _rev
        _type
        fluid(maxWidth: 800) {
          ...GatsbySanityImageFluid
        }
        metadata {
          lqip
          dimensions {
            width
            height
            aspectRatio
          }
        }
        url
      }
      crop {
        top
        right
        bottom
        left
      }
    }
  }
`;

export default categoryArchive;
